import css from 'styled-jsx/css';

export default css`
    .Slider :global(.slick-arrow) {
        z-index: 30;
    }

    .Slider :global(.slick-track) {
        display: flex;
    }

    .Slider :global(.slick-slide) {
        flex: 1;
        display: flex;
        height: auto !important;
    }

    .Slider :global(.slick-slide > div) {
        width: 100% !important;
    }

    .Slider :global(.slick-prev) {
        height: auto;
        width: auto;
        left: 0 !important;
    }

    .Slider :global(.slick-next) {
        height: auto;
        width: auto;
        right: 0 !important;
    }

    .Slider :global(.slick-arrow:before) {
        display: none;
    }

    .Slider :global(.slick-dots) {
        z-index: 30;
        bottom: 0;
    }

    .Slider :global(.slick-dots li) {
        margin: 0;
    }

    .Slider :global(.slick-dots li button:before) {
        font-size: 2.5rem;
        color: #dbd2c8;
        opacity: 100;
        width: 100%;
        height: 100%;
        font-size: inherit;
    }

    .Slider :global(.slick-dots li.slick-active button:before) {
        color: #90a6b0;
        opacity: 100;
    }
`;
