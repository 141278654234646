import React, { useState, useEffect, useRef } from 'react';

import Slick from 'react-slick';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import styles from './slider.styles';

interface SlideOptions {
    element: React.ReactNode;
    autoPlayDelay?: number;
}

interface SliderProps {
    slides: SlideOptions[];
    autoPlaySides?: boolean;
    defaultSlide?: number;
    defaultAutoPlaySpeed?: number;
    swipeSpeed?: number;
    showTimers?: boolean;

    // style options
    wrapperClass?: string;
    slideWrapperClass?: string;
    pagerClass?: string;
    pagerWrapperClass?: string;

    // arrow style options
    leftArrowClass?: string;
    rightArrowClass?: string;
    arrowSvgSizeClass?: string;

    // other options
    elementBeforePager?: React.ReactNode;
    elementAfterPager?: React.ReactNode;

    // slick options
    slidesToShow?: number;
    slidesToScroll?: number;
    lazyLoad?: boolean;
    customPaging?: (i: number) => React.ReactNode;

    // events

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRef?: (slick: React.MutableRefObject<any>) => void;
    onBeforeChange?: (prev: number, next: number) => void;
    onAfterChange?: (index: number) => void;
}

const Slider: React.FC<SliderProps> = props => {
    const slick = useRef(null);

    // workaround fix for setTimeout and state as specified on https://github.com/facebook/react/issues/14010
    const [speedIntervalId, setSpeedIntervalId] = useState(null);
    const speedIntervalRef = useRef(speedIntervalId);
    speedIntervalRef.current = speedIntervalId;

    const [speedValue, setSpeedValue] = useState(null);
    const speedRef = useRef(speedValue);
    speedRef.current = speedValue;

    const [intervalId, setIntervalId] = useState(null);
    const intervalRef = useRef(intervalId);
    intervalRef.current = intervalId;

    /**
     * Function used to tick the timer
     */
    const doRunInterval = () => {
        clearInterval(speedIntervalRef.current);

        const interval = setInterval(() => {
            if (speedRef.current >= 1) {
                setSpeedValue(speedRef.current - 1);
            } else {
                clearInterval(speedIntervalRef.current);
            }
        }, 1000);

        setSpeedIntervalId(interval);
        return interval;
    };

    /**
     * Gets the play speed from a specific slide via index from the sliders
     */
    const doAssignPlaySpeed = (index: number) => {
        let speed = props.defaultAutoPlaySpeed;
        const elem = props.slides[index];

        if (elem && elem.autoPlayDelay) {
            speed = elem.autoPlayDelay;
        }

        return speed;
    };

    /**
     * When invoked, will queue an timeout that will auto swipe the sliders just once
     */
    const doInitAutoSwipe = (index: number) => {
        if (!props.autoPlaySides) {
            return;
        }

        clearTimeout(intervalRef.current);
        const speed = doAssignPlaySpeed(index);

        if (slick.current) {
            const id = setTimeout(() => {
                slick.current.slickNext();
            }, speed * 1000);

            setSpeedValue(speed);
            setIntervalId(id);
        }

        doRunInterval();
    };

    /**
     * Resets the current active slide's video, if it has a video
     */
    const doResetCurrentVideo = () => {
        if (slick.current && slick.current.innerSlider && slick.current.innerSlider.list.querySelector) {
            // since the video element is a purely DOM element and is processed by
            // the client (the actual browser render). It only makes sense that we can only
            // manipulate it via the actual DOM itself
            const video = slick.current.innerSlider.list.querySelector('.slick-current video');

            if (video && video.promise) {
                setTimeout(() => {
                    if (video.promise) {
                        video.promise.then(() => {
                            video.pause();
                            video.currentTime = 0;
                        });
                    }
                }, props.swipeSpeed * 1000);
            }
        }
    };

    /**
     * Resumes the current active slide's video, if it has a video
     */
    const doResumeCurrentVideo = () => {
        if (slick.current && slick.current.innerSlider && slick.current.innerSlider.list.querySelector) {
            // since the video element is a purely DOM element and is processed by
            // the client (the actual browser render). It only makes sense that we can only
            // manipulate it via the actual DOM itself
            const video = slick.current.innerSlider.list.querySelector('.slick-current video');

            if (video) {
                video.promise = video.play();
            }
        }
    };

    useEffect(() => {
        doInitAutoSwipe(props.defaultSlide);

        return () => {
            clearTimeout(intervalRef.current);
            clearInterval(speedIntervalRef.current);
        };
    }, []);

    useEffect(() => {
        doResumeCurrentVideo();

        if (props.onRef) {
            props.onRef(slick);
        }
    }, [slick.current]);

    return (
        <div className={'Slider ' + props.wrapperClass}>
            <style jsx>{styles}</style>

            {props.showTimers ? (
                <div className="Timer absolute base-header-top inset-x-0 flex justify-center z-40 pt-3 opacity-[.75]">
                    <span className="text-white text-4xl">{speedValue}</span>
                </div>
            ) : (
                ''
            )}

            <Slick
                ref={slider => {
                    slick.current = slider;
                }}
                initialSlide={props.defaultSlide}
                beforeChange={(prev, next) => {
                    doResetCurrentVideo();

                    clearTimeout(intervalRef.current);
                    clearInterval(speedIntervalRef.current);

                    if (props.onBeforeChange) {
                        props.onBeforeChange(prev, next);
                    }
                }}
                afterChange={index => {
                    doInitAutoSwipe(index);
                    doResumeCurrentVideo();

                    if (props.onAfterChange) {
                        props.onAfterChange(index);
                    }
                }}
                lazyLoad={props.lazyLoad}
                dots={true}
                infinite={true}
                arrows={true}
                slidesToScroll={props.slidesToScroll}
                slidesToShow={props.slidesToShow}
                speed={props.swipeSpeed * 1000}
                appendDots={dots => (
                    <div>
                        {props.elementBeforePager ? props.elementBeforePager : ''}

                        <ul className={'slick-dots ' + props.pagerWrapperClass}>{dots}</ul>

                        {props.elementAfterPager ? props.elementAfterPager : ''}
                    </div>
                )}
                customPaging={
                    props.customPaging ? props.customPaging : i => <button className={props.pagerClass}>{i + 1}</button>
                }
                prevArrow={
                    <div>
                        <div className={'ArrowLeft hidden lg:flex justify-start items-center w-[60px] '}>
                            <FontAwesomeIcon
                                className={
                                    'text-tan opacity-[.40] hover:opacity-[.80] cursor-pointer w-[100px] ' +
                                    props.arrowSvgSizeClass
                                }
                                icon={faCaretLeft}
                            />
                        </div>
                    </div>
                }
                nextArrow={
                    <div>
                        <div className={'ArrowRight hidden lg:flex justify-end items-center '}>
                            <FontAwesomeIcon
                                className={
                                    'text-tan opacity-[.40] hover:opacity-[.80] cursor-pointer w-[100px] ' +
                                    props.arrowSvgSizeClass
                                }
                                icon={faCaretRight}
                            />
                        </div>
                    </div>
                }
            >
                {props.slides.map((element, index) => {
                    return (
                        <div className={'Slide ' + props.slideWrapperClass} key={index} data-slide-number={index}>
                            {element.element}
                        </div>
                    );
                })}
            </Slick>
        </div>
    );
};

Slider.defaultProps = {
    autoPlaySides: true,
    defaultSlide: 0,
    defaultAutoPlaySpeed: 3,
    swipeSpeed: 1.5,
    showTimers: false,

    wrapperClass: '',
    slideWrapperClass: '',
    pagerClass: 'text-2xl lg:text-3xl xl:text-4xl',
    pagerWrapperClass: '',

    leftArrowClass: 'md:ml-4 lg:ml-8',
    rightArrowClass: 'md:mr-4 lg:mr-8',
    arrowSvgSizeClass: 'h-[60px] w-[60px]',

    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: false,
};

export default Slider;
